import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TokenTest from "./pages/TokenTest";
import Resources from "./pages/Resources";

import { SentenceProvider } from "./contexts/SentenceContext";
import { IndexDescriptionProvider } from "./contexts/IndexDescriptionContext";
import { EmailProvider } from "./contexts/EmailContext";
import { HTMLProvider } from "./contexts/HTMLContext";
import { PromptProvider } from "./contexts/PromptContext";
import { FrenchProvider } from "./contexts/FrenchContext";
import { ArrayProvider } from "./contexts/ArrayContext";

function App() {
  const [data, setData] = useState("prompt");
  const [indexForAggregation, setIndexForAggregation] = useState("standard");
  const [level, setLevel] = useState("basic"); // or "multi"
  const [field, setField] = useState(""); // get from the Input.js
  return (
    <SentenceProvider>
      <EmailProvider>
        <HTMLProvider>
          <PromptProvider>
            <FrenchProvider>
              <ArrayProvider>
                <IndexDescriptionProvider>
                  <div className="App">
                    <BrowserRouter>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <Home
                              data={data}
                              setData={setData}
                              stringsToIndex={stringsToIndex}
                              indexForAggregation={indexForAggregation}
                              setIndexForAggregation={setIndexForAggregation}
                              level={level}
                              setLevel={setLevel}
                            />
                          }
                        />
                        <Route
                          path="/tokens"
                          element={
                            <TokenTest
                              data={data}
                              setData={setData}
                              stringsToIndex={stringsToIndex}
                              indexForAggregation={indexForAggregation}
                              setIndexForAggregation={setIndexForAggregation}
                              level={level}
                              setLevel={setLevel}
                              field={field}
                              setField={setField}
                            />
                          }
                        />
                        <Route path="/resources" element={<Resources />} />
                      </Routes>
                    </BrowserRouter>
                  </div>
                </IndexDescriptionProvider>
              </ArrayProvider>
            </FrenchProvider>
          </PromptProvider>
        </HTMLProvider>
      </EmailProvider>
    </SentenceProvider>
  );
}
const stringsToIndex = [
  {
    dataType: "prompt",
    dataString: "CHOOSE TEXT TO ANALYZE",
  },
  {
    dataType: "sentence",
    dataString:
      "As I was walking to work, I listened to 2 of Mike Lynn's podcasts, and I dropped my keys.",
  },
  {
    dataType: "email",
    dataString: "KarenLikeTheMeme@mongodb.com",
  },
  {
    dataType: "html",
    dataString: "<head>I demand to speak to the head of the department.</head>",
  },
  {
    dataType: "phrase",
    dataString:
      "Bonjour. Je m’appelle Thérèse, et je suis la gérante de cet établissement.",
  },
];

export default App;
