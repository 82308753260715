import React, { useState } from "react";
import { useForm } from "react-hook-form";

const InputForm = ({ field, setField }) => {
  const [submittedText, setSubmittedText] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const registerOptions = {
    newText: { required: "Feed me data" },
  };
  // searchtokens-qfsos
  const NewTextURL =
    "https://us-east-1.aws.data.mongodb-api.com/app/atlassearchindexes-swlql/endpoint/newText";

  const onSubmit = async (data) => {
    const id = Math.random().toString(36).slice(2);
    console.log("ID", id);
    setField(id); // in the document to search, id is the field name - {id: newText}

    data.field = id;
    console.log(data);

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(NewTextURL, requestOptions);
    const responseJSON = await response.json();
    setSubmittedText(true);

    console.log(responseJSON);
  };

  return (
    <form className="flex" onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        placeholder="Input your sample text here"
        {...register("newText", registerOptions.newText)}
        name="newText"
        autocomplete="off"
        className="w-5/6 text-xl px-4 py-2 rounded-lg flex-wrap"
      />
      <p className="text-red-500">
        {errors?.newText && errors.newText.message}
      </p>
      {submittedText ? (
        <div className="text-6xl ml-4">✅</div>
      ) : (
        <input
          className="text-white bg-green-700 ml-2 border-green-800 border-2 rounded-xl px-2 py-1"
          type="submit"
        />
      )}
    </form>
  );
};

export default InputForm;
