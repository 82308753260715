import React from "react";
import YouTube from "../images/youtube.png";
import Twitter from "../images/Twitterblue.png";
import Twitch from "../images/TwitchGlitchPurple.png";
import GitHub from "../images/GitHub.png";
import Facebook from "../images/Facebook.png";
import Community from "../images/Community.png";

const Footer = () => {
  return (
    <div className="flex bg-slateblue text-white px-12 justify-between text pb-2 border-t-2 border-white fixed  bottom-0">
      <div className="flex w-1/2 items-center">
        <div className="">
          Join our online{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.mongodb.com/community/forums/"
          >
            <span className="text-springgreen font-bold underline">
              MongoDB Community
            </span>
          </a>{" "}
          to interact with our product and engineering teams along with
          thousands of other MongoDB and Realm users.
        </div>
        <img
          src={Community}
          alt="twitter"
          className="mx-2 w-28 object-contain"
        />
      </div>
      <div className="relative"></div>
      <div className="flex space-x-10 pt-8">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/MongoDB"
        >
          {" "}
          <img src={Twitter} alt="twitter" className="w-12 object-contain" />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/user/mongodb"
        >
          {" "}
          <img src={YouTube} alt="YouTube" className="w-12 object-contain" />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.twitch.tv/mongodb"
        >
          <img src={Twitch} alt="Twitch" className="w-12 object-contain" />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/mongodb"
        >
          {" "}
          <img src={GitHub} alt="GitHub" className="w-12 object-contain" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/MongoDB"
        >
          {" "}
          <img src={Facebook} alt="Facebook" className=" w-12 object-contain" />
        </a>
      </div>

      <div className="flex items-end">© 2022 MongoDB, Inc.</div>
    </div>
  );
};

export default Footer;
