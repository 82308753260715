import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Presenter from "../components/Presenter";
import GitHub from "../images/GitHub.png";

const Resources = () => {
  return (
    <div className="bg-slateblue pb-10 min-h-screen">
      <Header />
      <div className="pb-16">
        <div className="my-10 font-bold text-center text-green-500  text-2xl">
          Additional Learning Resources
        </div>

        <div className="flex text-white">
          <div className="flex px-20 w-full justify-around">
            <div className="LIST space-y-4 w-1/2">
              <div className="DOCS text-lg ml-20">
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mongodb.com/docs/atlas/atlas-search/"
                >
                  Atlas Search: The Docs{" "}
                </a>
              </div>
              <div className="DOCS text-lg ml-20">
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mongodb.com/developer/search/?s=search#main"
                >
                  MongoDB Developer Center{" "}
                </a>
              </div>
              <div className="TUTORIALS text-lg ml-20">
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mongodb.com/docs/atlas/atlas-search/tutorials/"
                >
                  Various Atlas Search Tutorials{" "}
                </a>
              </div>
              <div className="COMPARISON text-lg ml-20">
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mongodb.com/compare/mongodb-atlas-search-vs-elastic-elasticsearch"
                >
                  Elastic Search vs MongoDB Atlas Search
                </a>
              </div>
            </div>
            <div className="DEMOS space-y-4 w-1/2">
              <div className="text-lg ml-20">
                What's Cooking Restaurant Finder Demo:{" "}
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.atlassearchrestaurants.com/"
                >
                  www.atlassearchrestaurants.com
                </a>
              </div>
              <div className="text-lg ml-20">
                MongoDB Netflix Clone:{" "}
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.atlassearchmovies.com/"
                >
                  www.atlassearchmovies.com
                </a>
              </div>
              <div className="text-lg ml-20">
                Atlas Search Indexes & Analyzers Demo:{" "}
                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.atlassearchindexes.com/"
                >
                  www.atlassearchindexes.com
                </a>
              </div>
              <div className="REPO flex text-lg ml-20">
                <img
                  src={GitHub}
                  alt="GitHub"
                  className="w-12 object-contain mr-10 "
                />

                <a
                  className="ml-4 text-center underline decoration-green-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/mongodb-developer/WhatsCooking"
                >
                  What's Cooking Git Repo
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex my-10 justify-around ">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/s2kXYZRE7pA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="border-2 border-springgreen"
          ></iframe>
          <iframe
            className="border-2 border-springgreen"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jnxnhbTO2RA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="flex my-20 justify-around">
          <iframe
            className="border-2 border-springgreen"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/o2ss2LJNZVE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gGv0l5Obi_Y"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="border-2 border-springgreen"
          ></iframe>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Resources;
