import React, { useState } from "react";

import SearchBar from "../components/SearchBar";
import SearchHero from "../images/SearchIllustration.png";
import Instructions from "../images/WorkingSteps.png";

import IndexTabs from "../components/IndexTabs";
import IndexTabsMulti from "../components/IndexTabsMulti";
import ListBox from "../components/ListBox";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = ({
  data,
  setData,
  stringsToIndex,
  indexForAggregation,
  setIndexForAggregation,
  level,
  setLevel,
}) => {
  return (
    <div className="bg-slateblue pb-10 min-h-screen">
      <Header />
      <div className="flex flex-col px-20">
        <div className="flex justify-center content-center space-x-6">
          <img src={SearchHero} alt="icon" className="w-48" />
          <div className="text-white text-3xl text-center my-auto">
            Why don’t you get the expected results for your search queries?{" "}
            <span className="text-6xl">🤷🏻‍♀️</span>
            <br></br>
            Maybe it's the analyzer.
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="bg-sun font-lexend rounded-xl shadow-2xl text-black text-center text-xl w-full py-5 px-10 mb-2 z-10">
            Use the tool below to see how the different Lucene analyzers
            tokenize your data and affect your search results.
          </div>
        </div>

        {/* ----------BELOW BANNER ----------- */}

        {/* ----------BEGIN CARD ----------- */}
        <div className="w-full flex bg-darkblue py-6 rounded-xl shadow-2xl">
          {level === "basic" ? (
            <div className="flex flex-col  z-10 w-1/2 px-10">
              <ListBox
                choice={data}
                setChoice={setData}
                choices={stringsToIndex}
              ></ListBox>
              <IndexTabs data={data} />
            </div>
          ) : (
            <div className="flex flex-col justify-center z-10">
              <ListBox
                choice={data}
                setChoice={setData}
                choices={stringsToIndexMulti}
              ></ListBox>
              <IndexTabsMulti data={data} />
            </div>
          )}
          {/* 2nd col */}
          <div className="lastRow flex flex-col">
            <img alt="instructions" src={Instructions} />

            {level === "basic" ? (
              <div className="flex w-full pr-10">
                <SearchBar
                  className="w-full"
                  indexForAggregation={indexForAggregation}
                  setIndexForAggregation={setIndexForAggregation}
                  data={data}
                  level={level}
                />
              </div>
            ) : (
              <div className="flex w-full pr-10">
                <SearchBar
                  className="w-full"
                  indexForAggregation={indexForAggregation}
                  setIndexForAggregation={setIndexForAggregation}
                  data={data}
                  level={level}
                  srcPage="Home"
                />
              </div>
            )}
          </div>
        </div>

        {/*------------ END CARD  --------*/}
      </div>
      <Footer />
    </div>
  );
};

const stringsToIndexMulti = [
  {
    dataType: "prompt",
    dataString: "CHOOSE TEXT TO ANALYZE",
  },
  {
    dataType: "sentence",
    dataString:
      "As I was walking to work, I listened to 2 of Mike Lynn's podcasts, and I dropped my keys.",
  },
  {
    dataType: "phrase",
    dataString:
      "Bonjour. Je m’appelle Thérèse, et je suis la gérante de cet établissement.",
  },
  {
    dataType: "favoriteMovies",
    dataString:
      '[ " Ne le Dis à Personne ", " Raiders of the Lost Ark ", " Rocky II " ]',
  },
];

export default Home;
