import React, { useState } from "react";
import { Link } from "react-router-dom";
import LOGO from "../images/Logomark.svg";

const Header = () => {
  const menuItems = [
    {
      id: 0,
      title: "Home",
      key: "/",
    },
    {
      id: 1,
      title: "Tokens",
      key: "/tokens",
    },
    {
      id: 2,
      title: "Resources",
      key: "/resources",
    },
  ];

  const pathname = window.location.pathname;

  return (
    <div className="flex w-full justify-between items-center bg-slateblue text-white border-b-2 border-white">
      <div className="flex text-2xl items-center">
        <img className="mx-auto my-auto h-16" src={LOGO} alt="logo" />
        Search Indexes + Analyzers = 💪💚
      </div>

      <div className="flex items-center ">
        {menuItems.map((item) => {
          return (
            <li
              key={item.id}
              className={`list-none py-2 px-4 mx-8 ${
                item.key === pathname &&
                "bg-white text-black py-2 px-1 rounded-md"
              }`}
            >
              <Link to={`${item.key}`}>{item.title}</Link>
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
