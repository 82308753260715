import React from "react";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Header from "../components/Header";
import Footer from "../components/Footer";
import InputForm from "../components/InputForm";
import ListBox from "../components/ListBox";

import Quote from "../images/Quote.png";
import Mapping from "../images/IndexMapping.png";
import SearchBar from "../components/SearchBar";
import IndexTabs from "../components/IndexTabs";
import { GiBookmarklet } from "react-icons/gi";
import { FaCoins } from "react-icons/fa";
import ExplainInstructions from "../images/ExplainInstructions.png";

const TokenTest = ({
  data,
  setData,
  stringsToIndex,
  indexForAggregation,
  setIndexForAggregation,
  level,
  setLevel,
  field,
  setField,
}) => {
  return (
    <div className="bg-slateblue pb-2 min-h-screen">
      <Header />
      {/* -----------------TOP HALF -----------------------*/}
      <div className="w-full flex px-12">
        <div className="px-20 w-1/3 text-center">
          <div className="text-white text-xl mt-8">
            Atlas Search uses{" "}
            <span className="text-yellow-400 font-bold text-2xl">
              inverted indexes
            </span>{" "}
            to query data. To make data searchable, we can break it up into{" "}
            <span className="text-yellow-400 font-bold text-2xl "> tokens</span>{" "}
            (or terms) to be indexed through a process called{" "}
            <span className=" font-bold text-2xl text-mauve">analysis</span>{" "}
            done by{" "}
            <span className="text-yellow-400 font-bold text-2xl  ">
              {" "}
              analyzers
            </span>
            .
          </div>
          <div className="flex justify-around py-6">
            {" "}
            <div className="text-6xl mt-4">🤔</div>
            <FaCoins
              className=" w-20 h-20 text-yellow-400 "
              aria-hidden="true"
            />
            <GiBookmarklet
              className="w-20 h-20 text-green-500 mr-20"
              aria-hidden="true"
            />
          </div>
          <div className="text-white text-xl mt-8 ">
            Armed with your new tokens, you can now think of an{" "}
            <span className="text-yellow-400 font-bold"> inverted index</span>{" "}
            as a glossary of tokens, like in the back of a book. You know how
            the glossary contains words or expressions pointing to specific
            pages? Well, search uses{" "}
            <span className="text-yellow-400 font-bold"> tokens</span> to point
            specific data documents.
          </div>
        </div>
        <div className="w-2/3 flex relative">
          {" "}
          <img
            src={Quote}
            alt="quote"
            className="absolute top-0 left-0 h-96 mx-auto shadow-slate-400 shadow my-4"
          />
          <img
            src={Mapping}
            alt="mapping"
            className="absolute mt-10 bottom-0 right-0 h-96 mx-auto shadow-slate-400 shadow my-4"
          />
        </div>
      </div>

      {/* -----------------END TOP HALF -----------------------*/}
      {/*------------------BEGIN TOOL AREA-----------------------*/}
      <div className="flex flex-col w-full px-24 mb-16">
        <div className="bg-sun font-lexend rounded-xl shadow-2xl mt-4 mb-2 text-black text-center text-xl w-full py-5 px-10 z-10">
          Now test your own token knowledge on your own data!
        </div>
        {/*------------------BEGIN TOKEN TOOL-----------------------*/}
        <div className="flex z-10 justify-around w-full bg-darkblue  rounded-xl p-6 shadow-2xl">
          {/* <div className="w-1/2">
            <IndexTabs data={data} />
          </div> */}
          <div className="flex flex-col z-10">
            <ListBox
              choice={data}
              setChoice={setData}
              choices={stringsToIndex}
            ></ListBox>
            <IndexTabs data={data} />
          </div>

          <div className="flex flex-col pl-6 w-1/2">
            <InputForm field={field} setField={setField} />
            <img
              alt="instructions"
              className="pl-20 w-4/5"
              src={ExplainInstructions}
            />
            <SearchBar
              indexForAggregation={indexForAggregation}
              setIndexForAggregation={setIndexForAggregation}
              data={data}
              level={level}
              srcPage="TokenTest"
              field={field}
              setField={setField}
            />
          </div>
        </div>
        {/*------------------END TOKEN TOOL-----------------------*/}
      </div>
      {/*------------------END TOOL AREA-----------------------*/}

      {/*-----------------RIGHT COLUMNS---------------*/}
      {/* <div className="w-1/3 flex flex-col my-8">
          <div className="flex flex-col z-10 mt-10 w-full bg-darkblue  rounded-xl p-6 shadow-2xl">
            <ListBox
              choice={data}
              setChoice={setData}
              choices={stringsToIndex}
            ></ListBox>

            <IndexTabs data={data} />
          </div>
        </div> */}
    </div>
  );
};

export default TokenTest;

let exampleDocument = {
  _id: 61453629643,
  sentence:
    "As I was walking to work, I listened to 2 of Mike Lynn's podcasts, and I dropped my keys. SMH!",
  email: "KarenLikeTheMeme@mongodb.com",
  html: "<head>I demand to speak to the head of the department.</head>",
  phrase:
    "Bonjour. Je m’appelle Thérèse, et je suis la gérante de cet établissement.",
  favoriteMovies: [
    "Ne le Dis à Personne",
    "Raiders of the Lost Ark",
    "Rocky II",
  ],
};
let ExampleString = JSON.stringify(exampleDocument, null, 2);

/* <div className="text-sm w-1/2 overflow-auto border-4 border-slate-700 shadow-xl">
        <SyntaxHighlighter language="javascript" style={atomDark}>
          {ExampleString}
        </SyntaxHighlighter>
      </div>
      
      
      */
